<template>
  <div>
    <div class="electron-bar">
      <div class="electron-bar-l"></div>
      <div class="electron-bar-r">
        <div id="btn-sub" title="最小化" @click="commandHandle('minimize')">
          <svg t="1705828751742" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3417" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><path d="M863.74455 544.00086 163.424056 544.00086c-17.664722 0-32.00086-14.336138-32.00086-32.00086s14.336138-32.00086 32.00086-32.00086l700.320495 0c17.695686 0 31.99914 14.336138 31.99914 32.00086S881.440237 544.00086 863.74455 544.00086z" fill="currentColor" p-id="3418"></path></svg>
        </div>
        <div id="btn-screen" title="最大化" @click="commandHandle('maximize')">
          <svg t="1705828726196" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2937" width="20" height="20" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M832 928.00086l-640 0c-52.9288 0-96.00086-43.07206-96.00086-95.99914l0-640c0-52.9288 43.07206-96.00086 96.00086-96.00086l640 0c52.92708 0 95.99914 43.07206 95.99914 96.00086l0 640C928.00086 884.9288 884.9288 928.00086 832 928.00086zM192 160.00086c-17.632039 0-32.00086 14.368821-32.00086 32.00086l0 640c0 17.664722 14.368821 31.99914 32.00086 31.99914l640 0c17.664722 0 31.99914-14.336138 31.99914-31.99914l0-640c0-17.632039-14.336138-32.00086-31.99914-32.00086L192 160.00086z" fill="currentColor" p-id="2938"></path></svg>
        </div>
        <div id="btn-close" title="关闭" @click="commandHandle('close')">
          <svg t="1705828743539" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3306" id="mx_n_1705828743539" xmlns:xlink="http://www.w3.org/1999/xlink" width="20" height="20"><path d="M557.311759 513.248864l265.280473-263.904314c12.54369-12.480043 12.607338-32.704421 0.127295-45.248112-12.512727-12.576374-32.704421-12.607338-45.248112-0.127295L512.127295 467.904421 249.088241 204.063755c-12.447359-12.480043-32.704421-12.54369-45.248112-0.063647-12.512727 12.480043-12.54369 32.735385-0.063647 45.280796l262.975407 263.775299-265.151458 263.744335c-12.54369 12.480043-12.607338 32.704421-0.127295 45.248112 6.239161 6.271845 14.463432 9.440452 22.687703 9.440452 8.160624 0 16.319527-3.103239 22.560409-9.311437l265.216826-263.807983 265.440452 266.240344c6.239161 6.271845 14.432469 9.407768 22.65674 9.407768 8.191587 0 16.352211-3.135923 22.591372-9.34412 12.512727-12.480043 12.54369-32.704421 0.063647-45.248112L557.311759 513.248864z" fill="currentColor" p-id="3307"></path></svg>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { generateNewStyle, writeNewStyle } from '@/utils/theme'

const store = useStore()
generateNewStyle(store.getters.mainColor).then((newStyleText) => {
  writeNewStyle(newStyleText)
})

const commandHandle = (type) => {
  window.parent.postMessage({ getCommandHandle: type }, '*')
}

</script>

<style lang="scss">
.electron-bar {
  -webkit-app-region: drag;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #FFFFFF;
  &-l {
    flex: 1;
    display: flex;
    height: 100%;
  }
  &-r {
    display: flex;
    -webkit-app-region:no-drag;
    min-width: 114px;
    div {
      display: flex;
    }
    svg {
      width: 18px !important;
      height: 18px !important;
      padding: 6px;
      border-radius: 4px;
      margin: 0 4px 4px;
      transition: all 0.2s;
      color: #5a5a5a;
      &:hover {
        color: #ffffff;
        background: #6F61E6;
        cursor: pointer;
      }
    }
    .electron-max {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>
