export const TOKEN = 'token'
export const USER_ID = 'userId'
// token 时间戳
export const TIME_STAMP = 'timeStamp'
// 超时时长(毫秒) 两小时
export const TOKEN_TIMEOUT_VALUE = 2 * 3600 * 1000
// 国际化
export const LANG = 'language'
// 主题色保存的 key
export const MAIN_COLOR = 'mainColor'
// 默认色值
export const DEFAULT_COLOR = '#6F61E6'
// tags
export const TAGS_VIEW = 'tagsView'
// 店铺列表Info
export const STORES_INFO = 'storesInfo'
// 店铺Info
export const STORE_INFO = 'storeInfo'
