const whiteList = ['/login', '/import', '/404', '/401']

/**
 * path 是否需要被缓存
 * @param {*} path
 * @returns
 */
export function isTags(path) {
  return !whiteList.includes(path)
}

export const merchantTypeTag = (platform, type) => {
  let typeName = '普通店'
  if (platform === 1) {
    switch (type) {
      case 1:
        typeName = '个人店'
        break
      case 2:
        typeName = '企业店'
        break
      case 3:
        typeName = '旗舰店'
        break
      case 4:
        typeName = '专卖店'
        break
      case 5:
        typeName = '专营店'
        break
    }
  }
  return typeName
}
