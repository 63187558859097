import { STORES_INFO, STORE_INFO } from '@/constant'
import { getItem, setItem } from '@/utils/storage'
export default {
  namespaced: true,
  state: () => ({
    storesInfo: getItem(STORES_INFO) || '',
    storeInfo: getItem(STORE_INFO) || ''
  }),
  mutations: {
    setStoresInfo(state, data) {
      let storesParse = []
      // state.storesInfo存在localStorage时
      if (state.storesInfo) {
        let isExist = false
        storesParse = state.storesInfo
        for (const i in storesParse) {
          const storesParseItem = storesParse[i]
          // 遍历店铺列表信息storesInfo，更新item id对应token, 开启存在开关
          if (data.id === storesParseItem.id) {
            data.token !== storesParseItem.token && Object.assign(storesParseItem, data)
            isExist = true
            break
          }
        }
        // 不存在时直接push进storesParse
        !isExist && (storesParse.push(data))
      } else {
        // state.storesInfo不存在localStorage时，直接push进storesParse
        storesParse.push(data)
      }
      setItem(STORES_INFO, storesParse)
    },
    setStoreInfo(state, data) {
      setItem(STORE_INFO, data)
      Object.assign(state.storeInfo, data)
    }
  },
  actions: {
    getStoresInfo () {
      console.log('获取店铺信息, stores/getStoresInfo')
    },
    getStoreInfo () {
      console.log('获取店铺信息, stores/getStoreInfo')
    }
  }
}
