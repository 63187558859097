<template>
  <div class="tags-view-container">
    <el-scrollbar
      class="tags-view-wrapper"
      ref="tagsViewScrollbar"
    >
      <router-link
        class="tags-view-item"
        :class="isActive(tag, true) ? 'active' : ''"
        :style="{
          backgroundColor: isActive(tag) ? $store.getters.cssVar.tagViewsList : '',
          borderColor: isActive(tag) ? $store.getters.cssVar.tagViewsList : ''
        }"
        v-for="(tag, index) in $store.getters.tagsViewList"
        :key="tag.fullPath"
        :to="{ path: tag.fullPath }"
        @contextmenu.prevent="openMenu($event, index)"
      >
        {{ tag.title }}
        <el-icon
          v-show="!isActive(tag)"
          class="el-icon-close"
          @click.prevent.stop="onCloseClick(index)"
        >
          <Close />
        </el-icon>
      </router-link>
    </el-scrollbar>
    <context-menu
      v-show="visible"
      :style="menuStyle"
      :index="selectIndex"
    ></context-menu>
  </div>
</template>

<script setup>
import ContextMenu from './ContextMenu.vue'
import { ref, reactive, watch, nextTick, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

const route = useRoute()
/**
 * 是否被选中
 */
const isActive = (tag, type) => {
  (type && tag.path === route.path) && pointset()
  return tag.path === route.path
}

/**
 * 关闭 tag 的点击事件
 */
const store = useStore()
const onCloseClick = (index) => {
  store.commit('app/removeTagsView', {
    type: 'index',
    index: index
  })
}

// contextMenu 相关
const selectIndex = ref(0)
const visible = ref(false)
const menuStyle = reactive({
  left: 0,
  top: 0
})
/**
 * 展示 menu
 */
const openMenu = (e, index) => {
  const { x, y } = e
  menuStyle.left = x + 'px'
  menuStyle.top = y + 'px'
  selectIndex.value = index
  visible.value = true
}

/**
 * 关闭 menu
 */
const closeMenu = () => {
  visible.value = false
}

/**
 * 监听变化
 */
watch(visible, (val) => {
  if (val) {
    document.body.addEventListener('click', closeMenu)
  } else {
    document.body.removeEventListener('click', closeMenu)
  }
})

onMounted(() => {
  scrollInit()
})

// 定位滚动锚点
const tagsViewScrollbar = ref(null)
const pointset = () => {
  nextTick(() => {
    const activeDom = document.getElementsByClassName('active')
    activeDom[0]?.offsetLeft && tagsViewScrollbar.value.setScrollLeft(activeDom[0].offsetLeft - 8)
  })
}

// 监听滚动条标签
const scrollInit = () => {
  // 获取要绑定事件的元素
  const scrollDiv = tagsViewScrollbar.value.wrap$
  // document.addEventListener('DOMMouseScroll', handler, false)
  // 添加滚轮滚动监听事件，一般是用下面的方法，上面的是火狐的写法
  scrollDiv.addEventListener('mousewheel', handler, false)
  // 滚动事件的出来函数
  function handler(event) {
    // 获取滚动方向
    const detail = event.wheelDelta || event.detail
    // 定义滚动方向，其实也可以在赋值的时候写
    const moveForwardStep = 1
    const moveBackStep = -1
    // 定义滚动距离
    let step = 0
    // 判断滚动方向,这里的100可以改，代表滚动幅度，也就是说滚动幅度是自定义的
    if (detail < 0) {
      step = moveForwardStep * 100
    } else {
      step = moveBackStep * 100
    }
    // 对需要滚动的元素进行滚动操作
    scrollDiv.scrollLeft += step
  }
}

</script>

<style lang="scss" scoped>
.tags-view-container {
  height: 44px;
  width: calc(100% - 180px);
  background: #fff;
  position: relative;
  &::after {
    content: "";
    width: 22px;
    height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    background: linear-gradient(to right, #ffffff00, #ffffff);
  }
  :deep .el-scrollbar__view {
    white-space: nowrap !important;
    display: inline-block;
    height: 44px;
  }
  :deep .el-scrollbar__wrap {
    white-space: nowrap !important;
    display: inline-block;
    overflow-y: hidden !important;
    overflow-x: auto;
    max-width: 100%;
  }
  :deep .el-scrollbar__bar.is-vertical {
    display: none !important;
  }
  .tags-view-item {
    display: inline-block;
    position: relative;
    cursor: pointer;
    height: 30px;
    line-height: 30px;
    border: 1px solid #6F61E6;
    color: #6F61E6;
    background: #fff;
    padding: 0 8px;
    font-size: 12px;
    margin-left: 8px;
    margin-top: 8px;
    border-radius: 4px;
    &:first-of-type {
      margin-left: 8px;
    }
    &:last-of-type {
      margin-right: 8px;
    }
    &.active {
      color: #fff;
      position: relative;
      padding-right: 16px;
      &::before {
        content: '';
        background: #fff;
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        position: absolute;
        right: 4px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
      &::after {
        content: '';
        width: 10px;
        height: 200%;
        top: -50%;
        position: absolute;
        right: 210px;
        background: linear-gradient(to right, #ffffff1a, #ffffff3d);
        box-shadow: 0px 0px 20px rgba(255, 255, 255 ,0.5);
        transform: rotate(30deg);
        animation: transMoveRight 0.3s 1;
        -webkit-animation: transMoveRight 0.3s 1;
      }
    }
    // close 按钮
    .el-icon-close {
      width: 16px;
      height: 16px;
      line-height: 10px;
      vertical-align: -2px;
      border-radius: 50%;
      text-align: center;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 100% 50%;
      &:before {
        transform: scale(0.6);
        display: inline-block;
        vertical-align: -3px;
      }
      &:hover {
        background-color: #6F61E6;
        color: #fff;
      }
    }
  }
}

@keyframes transMoveRight {
  from {
    right: 110%;
    opacity: 0;
  }
  to {
    right: -10%;
    opacity: 1;
  }
}
@-webkit-keyframes transMoveRight {
  from {
    right: 110%;
    opacity: 0;
  }
  to {
    right: -10%;
    opacity: 1;
  }
}

</style>
