<template>
  <div class="">
    <div class="logo-container">
      <el-avatar
        :size="logoHeight"
        shape="square"
        :src="Logo"
      />
      <h1 class="logo-title" v-if="$store.getters.sidebarOpened">
        科酷驴report
      </h1>
    </div>
    <el-scrollbar>
      <sidebar-menu></sidebar-menu>
    </el-scrollbar>
  </div>
</template>

<script setup>
import SidebarMenu from './SidebarMenu'
import Logo from '../../../assets/xiaodianlv.png'
import {} from 'vue'
const logoHeight = 28
</script>

<style lang="scss" scoped>
.logo-container {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo-title {
    margin-left: 6px;
    color: #443bcd;
    font-weight: 600;
    line-height: 50px;
    font-size: 16px;
    white-space: nowrap;
  }
  .el-avatar {
    --el-avatar-bg-color: none;
  }
}
</style>
