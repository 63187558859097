import axios from 'axios'
import store from '@/store'
import { ElMessage, ElLoading } from 'element-plus'
import loadingGif from '@/assets/loadingAgreatthinking.gif'
import { removeAllItem } from '@/utils/storage'
import router, { resetRouter } from '@/router'
// import { isCheckTimeout } from '@/utils/auth'
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 5000
})

let loadingInstance = null
const gifDom = ` <image xlink:href="${loadingGif}" x="25" y="25" height="100%" width="100%"></image>`

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    loadingInstance = ElLoading.service({
      spinner: gifDom,
      customClass: 'loadingGif',
      background: '#00000020'
    })
    const { time } = getTestICode()
    config.headers.codeType = time
    // 在这个位置需要统一的去注入token
    // if (store.getters.token) {
    // 如果token存在 注入token
    config.headers['User-Id'] = `${store.getters.userId}`
    config.headers.Authorization = `${store.getters.token}`
    // if (isCheckTimeout()) {
    //   // 登出操作
    //   store.dispatch('user/logout')
    //   return Promise.reject(new Error('token 失效'))
    // }
    // }
    // 配置接口国际化
    config.headers['Accept-Language'] = store.getters.language
    return config // 必须返回配置
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    loadingInstance.close()
    const { code, message, data } = response.data
    //   要根据success的成功与否决定下面的操作
    if (code === 200) {
      // 成功返回解析后的数据
      return data
    } else {
      // 特殊错误码处理
      const aloneCode = [10004, 20001, 10002]
      if (aloneCode.indexOf(code) !== -1) {
        ElMessage.error(message)
        resetRouter()
        store.commit('user/setToken', '')
        store.commit('user/setUserInfo', {})
        removeAllItem()
        router.push('/login')
        return Promise.reject(new Error())
      }
      // 业务错误
      ElMessage.error(message) // 提示错误消息
      return Promise.reject(new Error(message))
    }
  },
  (error) => {
    // 处理 token 超时问题
    // if (
    //   error.response &&
    //   error.response.data &&
    //   error.response.data.code === 401
    // ) {
    //   // token超时
    //   store.dispatch('user/logout')
    // }
    loadingInstance.close()
    ElMessage.error(error.message) // 提示错误信息
    return Promise.reject(error)
  }
)

/**
 * 返回 Icode 的实现
 */
function getTestICode() {
  const now = parseInt(Date.now() / 1000)
  return {
    time: now
  }
}
export default service
