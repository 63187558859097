import request from '@/utils/request'

/**
 * 主账号 - 注册
 */
export const register = (data) => {
  return request({
    url: '/user/register',
    method: 'POST',
    data
  })
}

/**
 * 主账号 - 修改密码
 */
export const updatePassword = (data) => {
  return request({
    url: '/user/modify-password',
    method: 'POST',
    data
  })
}

/**
 * 主账号 - 忘记密码
 */

/**
 * 主账号 - 个人中心
 */
export const getUserInfo = () => {
  return request({
    url: '/user/info'
  })
}

/**
 * 主账号 - 创建用户组
 */
export const groupAdd = (data) => {
  return request({
    url: '/user/group/add',
    method: 'POST',
    data
  })
}

/**
 * 主账号 - 更新用户组
 */
export const groupUpdate = (data) => {
  return request({
    url: '/user/group/update',
    method: 'POST',
    data
  })
}

/**
 * 主账号 - 用户组分页列表
 */
export const groupPageList = (params) => {
  return request({
    url: '/user/group/page-list',
    params
  })
}

/**
 * 主账号 - 用户组列表
 */
export const groupList = () => {
  return request({
    url: '/user/group/list'
  })
}

/**
 * 主账号 - 更新用户组权限
 */
export const permissionsUpdate = (data) => {
  return request({
    url: '/user/permissions/update',
    method: 'POST',
    data
  })
}

/**
 * 主账号 - 用户权限
 */
export const permissionsInfo = (params) => {
  return request({
    url: '/user/permissions/info',
    method: 'GET',
    params
  })
}

/**
 * 子账号 - 添加
 */
export const userChildAdd = (data) => {
  return request({
    url: '/user/child/add',
    method: 'POST',
    data
  })
}

/**
 * 子账号 - 更新
 */
export const userChildUpdate = (data) => {
  return request({
    url: '/user/child/update',
    method: 'POST',
    data
  })
}

/**
 * 子账号 - 获取账号信息
 */
export const userChildInfo = (params) => {
  return request({
    url: '/user/child/info',
    method: 'GET',
    params
  })
}

/**
 * 子账号 - 获取分页列表
 */
export const userChildPageList = (params) => {
  return request({
    url: '/user/child/page-list',
    method: 'GET',
    params
  })
}

/**
 * 账号 - 登录
 */
export const login = (data) => {
  return request({
    url: '/user/login',
    method: 'POST',
    data
  })
}

/**
 * 账号 - 登出
 */
export const outLogin = () => {
  return request({
    url: '/user/logout'
  })
}

/**
 * 获取验证码
 */
export const getVerifyCode = () => {
  return request({
    url: '/common/verify-code'
  })
}
