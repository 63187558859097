import md5 from 'md5'
import { setItem, getItem, removeAllItem } from '@/utils/storage'
import { TOKEN, USER_ID } from '@/constant'
import router, { resetRouter } from '@/router'
import { setTimeStamp } from '@/utils/auth'
import { login, register, outLogin, getUserInfo } from '@/api/user'
import defaultImg from '@/assets/defaultImg.jpg'

export default {
  namespaced: true,
  state: () => ({
    token: getItem(TOKEN) || '',
    userId: getItem(USER_ID) || '',
    userInfo: {}
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
      setItem(TOKEN, token)
    },
    setUserId(state, userId) {
      state.userId = userId
      setItem(USER_ID, userId)
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    }
  },
  actions: {
    /**
     * 登录请求动作
     *
     */
    login(context, userInfo) {
      const { username, password, picCode, picCodeKey } = userInfo
      return new Promise((resolve, reject) => {
        login({
          account: username,
          code: picCode,
          code_key: picCodeKey,
          password: md5(password)
        })
          .then((data) => {
            this.commit('user/setToken', data.token)
            this.commit('user/setUserId', data.user_id)
            // 登录后操作
            router.push('/')
            // 保存登录时间
            setTimeStamp()
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     * 注册请求动作
     *
     */
    register(context, userInfo) {
      const { username, password, picCode, picCodeKey } = userInfo
      return new Promise((resolve, reject) => {
        register({
          account: username,
          code: picCode,
          code_key: picCodeKey,
          password: md5(password)
        })
          .then((data) => {
            this.commit('user/setToken', data.token)
            this.commit('user/setUserId', data.user_id)
            // 登录后操作
            router.push('/')
            // 保存登录时间
            setTimeStamp()
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    /**
     *
     * 获取用户信息
     */
    async getUserInfo(context) {
      const res = await getUserInfo()
      console.log('~res1res1res1res1~~', res)
      const { account: username, id, avatar } = res
      const userInfo = {
        role: [
          {
            id: '1',
            title: '超级管理员'
          }
        ],
        id: id,
        username: username,
        title: '超级管理员',
        avatar: avatar || defaultImg,
        permission: {
          menus: [
            // 'userManage',
            // 'articleRanking',
            // 'articleCreate'
          ],
          points: [
            // 'distributeRole',
            // 'importUser',
            // 'removeUser',
            // 'distributePermission'
          ]
        }
      }
      console.log('mock getUserInfo数据', userInfo)
      this.commit('user/setUserInfo', userInfo)
      return userInfo
    },
    // 退出登录
    async logout() {
      await outLogin()
      resetRouter()
      this.commit('user/setToken', '')
      this.commit('user/setUserInfo', {})
      removeAllItem()
      router.push('/login')
    }
  }
}
